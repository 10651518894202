import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = ({ data }) => {
  const post = data.markdownRemark
  return (
  <Layout>
    <SEO title={post.frontmatter.title} />
    <div dangerouslySetInnerHTML={{ __html: post.html }} />
  </Layout>
)}

export const contentQuery = graphql`
  query data($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
      }
    }
  }
`

export default Content
